.vhw-teaser {
  background: #ecf5fd;
  padding: 26px;
  margin-bottom: 26px;
}
.vhw-teaser .vhw-teaser-headline {
  background: #7fabd0;
  margin: -25px -25px 0;
  padding: 16px 26px 18px;
}
.vhw-teaser .vhw-teaser-headline h2 {
  color: #ffffff;
  margin: 0;
  font-size: 16px;
}
.vhw-teaser .vhw-teaser-title {
  margin: 0;
  padding-top: 26px;
}
.vhw-teaser .vhw-teaser-text {
  padding-top: 10px;
}
.vhw-teaser .vhw-teaser-solrurl {
  margin-bottom: 0;
  text-align: right;
}
